import { from } from 'rxjs';
import axios from 'axios';

const { ROOT_API } = process.env;

export const observableRequest = ({
  url,
  config = { method: 'GET' },
  headers = null,
  token = '',
  currentRole,
}) => {
  console.log('token', token);

  const authHeader = token
    ? {
        Authorization: `Bearer ${token}`,
        current_Role_Id: currentRole,
      }
    : {};

  const axiosConfig = {
    url: `${ROOT_API}/${url}`,
    data: config?.body,
    headers: headers
      ? {
          ...headers,
          ...authHeader,
        }
      : {
          'Content-Type': 'application/json',
          ...authHeader,
        },
    ...config,
  };

  // Use Axios and wrap it in an observable
  return from(axios(axiosConfig));
};

export const requestWithDispatch = (dispatch, token = null) => async ({
  url,
  action = '',
  ...rest
}) => {
  const authHeader = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
  dispatch({ type: `${action}_BEGIN`.toUpperCase() });
  try {
    const response = await axios({
      url: `${ROOT_API}/${url}`,
      headers: {
        'Content-Type': 'application/json',
        ...authHeader,
      },
      ...rest,
    });
    dispatch({
      type: `${action}_SUCCESS`.toUpperCase(),
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: `${action}_FAILURE`.toUpperCase(),
      payload: error.response,
    });
  } finally {
    dispatch({ type: `${action}_END`.toUpperCase() });
  }
};
