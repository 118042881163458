/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Segment, Form, Modal } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import { selectSessionAuthToken } from '@tg/core/store/modules/session';
import { useSelector } from 'react-redux';
import { formatYMD, getYears } from '@tg/core/utils/datetimeHelpers';
import { useResource } from '@tg/core/hooks';
import {
  Container,
  Heading,
  DateField,
  Button,
  LabelledInput,
  SelectField,
} from '@tg/core/components';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import TeamedActivitiesReport from './TeamedActivitiesReport';

const {
  REPORT: {
    PLANNER_REPORT,
    ALL_EMPLOYEES_REPORT,
    SALARY_INCREASE_REPORT,
    ACTIVITY_LOG,
    OFFICER_REPORT,
    TIME_OFF_TAKEN,
  },
} = permissions;

const Reports = () => {
  const { register, control, handleSubmit, errors: fieldErrors } = useForm();
  const { t } = useTranslation('forms', ['datetime', 'reports']);
  const token = useSelector(state => selectSessionAuthToken(state));
  const [loading, setLoading] = useState(false);
  const [employeeReportLoading, setEmployeeReportLoading] = useState(false);
  const accesslist = useSelector(state => state?.access?.roles);
  const [successModal, setSuccessModal] = useState(false);
  const [failModal, setFailModal] = useState(null);
  const today = new Date();
  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());
  const [invoiceMonth, setInvoiceMonth] = useState(today.getMonth());
  const [invoiceYear, setInvoiceYear] = useState(today.getFullYear());
  const [timeOffYear, setTimeOffYear] = useState(today.getFullYear());

  const { postResource } = useResource({ url: 'reports/salary_increase' });
  const { getResource } = useResource({ url: 'reports/officers' });
  const { getResource: getTimeOffReport } = useResource({
    url: `reports/leave_balance_report?year=${timeOffYear}`,
  });

  const { postResource: postGetInvoiceReport } = useResource({
    url: 'reports/codat_invoice',
  });
  const onSubmit = values => {
    const { report_type, date_from, date_to } = values;
    const params =
      report_type === 'planner'
        ? {
            date_from: formatYMD(date_from),
            date_to: formatYMD(date_to),
          }
        : {};
    if (report_type === 'all_employees') {
      setEmployeeReportLoading(true);
    } else {
      setLoading(true);
    }

    return observableRequest({
      url: `reports/${report_type}`,
      token,
      headers: {},
      config: {
        method: 'POST',
        body: {
          report_type,
          ...params,
        },
      },
    }).subscribe(
      () => {
        setLoading(false);
        setEmployeeReportLoading(false);
        setSuccessModal(true);
      },
      error => {
        setLoading(false);
        setEmployeeReportLoading(false);
        setFailModal(error.response.error);
      },
    );
  };

  const getInvoiceReport = () => {
    const invoiceMonthCopy = invoiceMonth + 1;
    postGetInvoiceReport({
      formData: {
        year: invoiceYear,
        month: invoiceMonthCopy,
      },
      onSuccess: response => {
        const res = 'not_found';
        if (response.status === res) {
          setFailModal(response.json.message);
        } else {
          setSuccessModal(true);
        }
      },
      onError: error => {
        setFailModal(error.response.error);
      },
    });
  };

  const currentYear = new Date().getFullYear();

  const YearOptions = Array.from({ length: 6 }, (_, index) => {
    const year = currentYear - 5 + index;
    return {
      text: year.toString(),
      value: year,
    };
  }).sort((a, b) => b.value - a.value);

  const getTimeOffReports = () => {
    getTimeOffReport({
      onSuccess: response => {
        const res = 'not_found';
        if (response.status === res) {
          setFailModal(response.json.message);
        } else {
          setSuccessModal(true);
        }
      },
      onError: error => {
        setFailModal(error?.response?.error);
      },
    });
  };

  const onSalaryIncrease = () => {
    const increaseMonth = month + 1;
    postResource({
      formData: {
        year,
        month: increaseMonth,
      },
      onSuccess: response => {
        const res = 'not_found';
        if (response.status === res) {
          setFailModal(response.json.message);
        } else {
          setSuccessModal(true);
        }
      },
      onError: error => {
        setFailModal(error.response.error);
      },
    });
  };

  const monthOptions = getYears.reduce((acc, _year) => {
    Array.from(new Array(12)).forEach((_, index) => {
      acc.push({
        key: `${_year}-${index}`,
        text: `${t(`datetime:monthnames.${index}`)} ${_year}`,
        value: { month: index, year: _year },
      });
    });
    return acc;
  }, []);

  const inVoiceMonthOptions = getYears.sort().reduce((acc, _year) => {
    Array.from(new Array(12)).forEach((_, index) => {
      acc.push({
        key: `${_year}-${index}`,
        text: `${t(`datetime:monthnames.${index}`)} ${_year}`,
        value: { invoiceMonth: index, invoiceYear: _year },
      });
    });
    return acc;
  }, []);

  return (
    <>
      <Container>
        {getPermission(accesslist, PLANNER_REPORT) && (
          <Segment>
            <Heading level='h3'>Planner report</Heading>
            <p>{t('reports:planner')}</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <input
                type='hidden'
                name='report_type'
                defaultValue='planner'
                ref={register}
              />

              <Form.Group>
                <Form.Field required error={!!fieldErrors.date_from}>
                  <label>Date from</label>
                  <Controller
                    as={DateField}
                    control={control}
                    name='date_from'
                    defaultValue={new Date()}
                    rules={{ required: t('fieldErrors.required') }}
                  />
                  {fieldErrors.date_from && (
                    <p>{fieldErrors.date_from.message}</p>
                  )}
                </Form.Field>

                <Form.Field required error={!!fieldErrors.date_to}>
                  <label>Date to</label>
                  <Controller
                    as={DateField}
                    control={control}
                    name='date_to'
                    defaultValue={new Date()}
                    rules={{ required: t('fieldErrors.required') }}
                  />
                  {fieldErrors.date_to && <p>{fieldErrors.date_to.message}</p>}
                </Form.Field>
              </Form.Group>

              <Button loading={loading} type='submit'>
                Get report
              </Button>
            </Form>
          </Segment>
        )}

        {getPermission(accesslist, ALL_EMPLOYEES_REPORT) && (
          <Segment>
            <Heading level='h3'>All employees report</Heading>
            <p>{t('reports:employees')}</p>
            <Button
              loading={employeeReportLoading}
              type='button'
              onClick={() => {
                onSubmit({ report_type: 'all_employees' });
              }}
            >
              Get report
            </Button>
          </Segment>
        )}

        {getPermission(accesslist, OFFICER_REPORT) && (
          <Segment>
            <Heading level='h3'>All Officer Reports</Heading>
            <p style={{ marginBottom: '10px' }}>
              Report of all officer data in a single CSV.
            </p>
            <Button
              onClick={() =>
                getResource({
                  onSuccess: () => {
                    setSuccessModal(true);
                  },
                })
              }
            >
              Get report
            </Button>
          </Segment>
        )}

        {getPermission(accesslist, SALARY_INCREASE_REPORT) && (
          <Segment>
            <Heading level='h3'>Salary increase report</Heading>
            <p style={{ marginBottom: '10px' }}>
              {t('reports:salary_increase')}
            </p>
            <div className='w-80 mb-4'>
              <LabelledInput id='data-filter' label='Month'>
                <SelectField
                  options={monthOptions}
                  defaultValue={{ month, year }}
                  onChange={val => {
                    setYear(val.year);
                    setMonth(val.month);
                  }}
                />
              </LabelledInput>
            </div>
            <Button onClick={onSalaryIncrease}>Get report</Button>
          </Segment>
        )}
        {getPermission(accesslist, ACTIVITY_LOG) && (
          <TeamedActivitiesReport
            setSuccessModal={setSuccessModal}
            setFailModal={setFailModal}
          />
        )}
        <Segment>
          <Heading level='h3'>Invoice report</Heading>
          <p style={{ marginBottom: '10px' }}>{t('reports:invoice')} </p>
          <div className='w-80 mb-4'>
            <LabelledInput id='data-filter' label='Month'>
              <SelectField
                options={inVoiceMonthOptions}
                defaultValue={{ invoiceMonth, invoiceYear }}
                onChange={val => {
                  setInvoiceYear(val.invoiceYear);
                  setInvoiceMonth(val.invoiceMonth);
                }}
              />
            </LabelledInput>
          </div>
          <Button onClick={getInvoiceReport}>Get report</Button>
        </Segment>

        {getPermission(accesslist, TIME_OFF_TAKEN) && (
          <Segment>
            <Heading level='h3'>Time off taken report</Heading>
            <p style={{ marginBottom: '10px' }}>{t('reports:time_off')} </p>
            <div className='w-80 mb-4'>
              <LabelledInput id='data-filter' label='Year'>
                <SelectField
                  defaultValue={timeOffYear}
                  options={YearOptions}
                  onChange={val => {
                    setTimeOffYear(val);
                  }}
                />
              </LabelledInput>
            </div>
            <Button onClick={getTimeOffReports}>Get report</Button>
          </Segment>
        )}
      </Container>

      <Modal
        open={successModal}
        header='Report created'
        content='Your report will be emailed to you shortly.'
        actions={['OK']}
        onClose={() => setSuccessModal(false)}
      />

      <Modal
        open={!!failModal}
        header='Report could not be created'
        content={failModal}
        actions={['OK']}
        onClose={() => setFailModal(null)}
      />
    </>
  );
};

export default Reports;
