import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createActions } from '@tg/core/utils/actionHelpers';

export const actions = {
  ...createActions({
    resource: 'application',
    verbs: ['bootstrap'],
    prefix: 'app',
  }),
};

const initialState = {
  config: {},
  bootstrap: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.BOOTSTRAP_APPLICATION_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        api_version: data.api_version,
        bootstrap: true,
      };
    }

    default:
      return state;
  }
};

export const epic = (action$, _, { observableRequest }) =>
  action$.pipe(
    ofType('persist/REHYDRATE'),
    switchMap(() => {
      return observableRequest({
        url: 'webapp',
      }).pipe(
        map(response =>
          actions.bootstrapApplicationSuccess({ data: response?.data?.data }),
        ),
        catchError(({ response }) =>
          of(actions.bootstrapApplicationFailure(response)),
        ),
      );
    }),
  );
