import { of, timer } from 'rxjs';
import { map, groupBy, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import actions from './actions';

export const fetchCollectionEpic = (action$, _, { observableRequest }) =>
  action$.pipe(
    ofType(actions.FETCH_COLLECTION),
    groupBy(
      ({ payload }) => payload.resource,
      f => f,
      group$ =>
        group$.pipe(
          // close existing streams if no event of a grouped action
          // is emitted 5 seconds in a row (prevents memory leaks)
          switchMap(() => timer(5000)),
        ),
    ),
    mergeMap(actionsGroupedByResource$ =>
      actionsGroupedByResource$.pipe(
        switchMap(({ payload }) => {
          const { resource } = payload;
          return observableRequest({
            url: resource,
          }).pipe(
            map(response =>
              actions.fetchCollectionSuccess({ data: response.data, resource }),
            ),
            catchError(({ response }) =>
              of(actions.fetchCollectionFailure(response)),
            ),
          );
        }),
      ),
    ),
  );

export default combineEpics(fetchCollectionEpic);
