/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { formatDate } from '@tg/core/utils/datetimeHelpers';
import {
  List,
  FormBuilder,
  Button,
  PaginationControl,
} from '@tg/core/components';
import { useSelector } from 'react-redux';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: { BENEFITS_ADD, BENEFITS_REMOVE },
} = permissions;
const ContractBenefits = () => {
  const { contractId } = useParams();
  const [page, setPage] = useState(1);
  const accesslist = useSelector(state => state?.access?.roles);

  const { data, errors, isFetching, pagination, postResource, deleteResource } =
    useResource(
      {
        url: `contracts/${contractId}/benefits?page=${page}&pagesize=10`,
      },
      true,
    );

  const onSubmit = formData => {
    const {
      contract_benefit: { start_date },
    } = formData;
    const startDate = `${start_date.getDate()}/${
      start_date.getMonth() + 1
    }/${start_date.getFullYear()}`;
    const formDataCopy = {
      ...formData,
      contract_benefit: {
        ...formData.contract_benefit,
        start_date: startDate,
      },
    };
    postResource({ formData: formDataCopy });
  };

  const onDeleteSuccess = () => {
    // Edge Case Scenario
    if (page > 1 && data.length % 10 === 1) {
      setPage(page - 1);
    }
  };

  const onDelete = id => {
    deleteResource({ id, onSuccess: onDeleteSuccess });
  };

  return (
    <>
      {/* <Heading level='h3'>Benefits</Heading> */}

      {getPermission(accesslist, BENEFITS_ADD) && (
        <FormBuilder
          onSubmit={onSubmit}
          isFetching={isFetching}
          errors={errors}
          fieldsets={[{ id: 'contract_benefit', fieldset: 'contract_benefit' }]}
          submitButtonText='Add benefit'
        />
      )}

      <div className='mt-10'>
        {data && !!data.length && (
          <List
            headings={[
              { text: 'Benefit' },
              { text: 'Type' },
              { text: 'Start Date', width: '100px' },
              { text: '', align: 'right', width: '100px' },
            ]}
            items={data.map(({ id, name, start_date, benefit_type }) => ({
              id,
              columns: [
                benefit_type.name,
                <span className='whitespace-normal'>{name}</span>,
                formatDate({ dateStr: start_date }),
                <span>
                  {getPermission(accesslist, BENEFITS_REMOVE) && (
                    <Button
                      color='tertiary'
                      size='small'
                      onClick={event => {
                        event.preventDefault();
                        onDelete(id);
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </span>,
              ],
            }))}
          />
        )}

        {data && !data.length && !isFetching && 'No Benefits Found'}
        {pagination && (
          <div>
            <PaginationControl {...pagination} onPageChange={setPage} />
          </div>
        )}
      </div>
    </>
  );
};

export default ContractBenefits;
