import React, { useState } from 'react';
import { pluck } from 'rxjs/operators';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Heading, FormBuilder } from '@tg/core/components';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import { actions } from '@tg/core/store/modules/session';
import AuthErrors from '../AuthErrors';

const Login = () => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [apiError, setApiError] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const { from } = location.state || { from: { pathname: '/' } };

  const onSubmit = formData => {
    setIsFetching(true);
    observableRequest({
      url: `auth/login`,
      config: {
        method: 'POST',
        body: { ...formData.login, portal: ['Administrator'] },
      },
    })
      .pipe(pluck('data'))
      .subscribe(
        response => {
          dispatch(actions.createSession({ token: response.token }));
          history.replace(from);
        },
        error => {
          dispatch(actions.destroySession());
          setApiError(error.response);
          setIsFetching(false);
        },
      );
  };

  return (
    <>
      <Heading level='h1'>{t('login.title')}</Heading>
      <AuthErrors error={apiError} />

      <FormBuilder
        onSubmit={onSubmit}
        isFetching={isFetching}
        errors={null}
        fieldsets={[
          {
            id: 'login',
            fieldset: 'login',
            title: null,
            afterFields: <Link to='forgot'>{t('login.linkText')}</Link>,
          },
        ]}
        submitButtonText={t('login.submitButton')}
        submitButtonProps={{
          fluid: true,
        }}
      />
    </>
  );
};

export default Login;
