import { of } from 'rxjs';
import { map, switchMap, concatMap, catchError, filter } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { selectSessionAuthToken } from './selectors';
import actions from './actions';

export const rehydrateUserEpic = action$ =>
  action$.pipe(
    ofType('persist/REHYDRATE'),
    filter(({ payload }) => payload && payload.session.aut),
    map(() => actions.fetchUser()),
  );

export const createSessionEpic = action$ =>
  action$.pipe(
    ofType(actions.CREATE_SESSION),
    concatMap(() => {
      return of(actions.fetchUser());
    }),
  );

export const fetchUserEpic = (action$, store$, { observableRequest }) => {
  return action$.pipe(
    ofType(actions.FETCH_USER),
    switchMap(() => {
      const token = selectSessionAuthToken(store$.value);
      return observableRequest({
        url: 'users/me',
        token,
      }).pipe(
        map(response => actions.fetchUserSuccess(response?.data?.data)),
        catchError(({ response }) => of(actions.fetchUserFailure(response))),
      );
    }),
  );
};

export default combineEpics(
  rehydrateUserEpic,
  fetchUserEpic,
  createSessionEpic,
);
